import { FC } from "react";
import { SidebarContextProvider } from "../../../context/SidebarContext";
import { Sidebar } from "../../organisms/Sidebar";
import { AuthenticatedLayoutProps } from "./AuthenticatedLayout.types";
import styles from "./AuthenticatedLayout.module.scss";
import { KeyboardShortcutsDialogProvider } from "../../atoms/KeyboardShortcutsDialog/KeyboardShortcutsDialog";
import { Tooltip } from "../../molecules/Tooltip";
import { Button, ButtonVariant } from "../../atoms/Button";
import { Question } from "../../atoms/icons";
import { useTranslation } from "react-i18next";

export const AuthenticatedLayout: FC<AuthenticatedLayoutProps> = ({ children, isScrollable = false }) => {
  const { t } = useTranslation();

  return (
    <KeyboardShortcutsDialogProvider>
      <SidebarContextProvider>
        <div className={styles.page}>
          <Sidebar />
          <div className={styles.pageWrapper} data-testid="page">
            <div className={isScrollable ? styles.scrollableContent : styles.pageContent}>
              {children}
              {process.env.NEXT_PUBLIC_VERSION && (
                <div className={styles.version}>
                  <span>v{process.env.NEXT_PUBLIC_VERSION}</span>
                </div>
              )}
              {process.env.NEXT_PUBLIC_ENV === "ACCEPTANCE" && (
                <div className={styles.toolTipWrapper}>
                  <Tooltip content={t("acceptance_environment.text")}>
                    <div className={styles.environmentWarning}>
                      <div className={styles.environmentWarningInner}>
                        <span className={styles.environmentWarningInnerText}>{t("acceptance_environment.title")}</span>
                        <Button variant={ButtonVariant.PLAIN} icon={<Question />}></Button>
                      </div>
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
      </SidebarContextProvider>
    </KeyboardShortcutsDialogProvider>
  );
};
