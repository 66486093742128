import { useRouter } from "next/router";
import React, { PropsWithChildren, useState } from "react";

type SidebarContextType = {
  isSidebarMinimized: boolean;
  setShowSettings: (value: boolean) => void;
  showSettings: boolean;
  setShowReports: (value: boolean) => void;
  showReports: boolean;
  setShowRubrics: (value: boolean) => void;
  showRubrics: boolean;
  toggleMinimized: () => void;
};

export const defaultSidebarContext: SidebarContextType = {
  isSidebarMinimized: false,
  setShowSettings: () => {},
  showSettings: false,
  setShowRubrics: () => {},
  showRubrics: false,
  setShowReports: () => {},
  showReports: false,
  toggleMinimized: () => {},
};

export const SidebarContext = React.createContext<SidebarContextType>(defaultSidebarContext);

export const SidebarContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter();
  const initialMinimized =
    typeof window !== "undefined" ? JSON.parse(localStorage.getItem("isSidebarMinimized")!) : false;
  const initialSettingsMenu = pathname.includes("settings"); // if we are on settings page, open settings submenu
  const initialReportsMenu = pathname.includes("report"); // if we are on reports page, open reports submenu
  const initialRubricsMenu = pathname.includes("rubrics"); // if we are on rubrics page, open rubrics submenu
  const [isSidebarMinimized, setIsSidebarMinimized] = React.useState(initialMinimized);
  const [showSettings, setShowSettings] = useState(initialSettingsMenu);
  const [showReports, setShowReports] = useState(initialReportsMenu);
  const [showRubrics, setShowRubrics] = useState(initialRubricsMenu);

  const toggleMinimized = () => {
    localStorage.setItem("isSidebarMinimized", JSON.stringify(!isSidebarMinimized));
    setIsSidebarMinimized(!isSidebarMinimized);
  };

  return (
    <SidebarContext.Provider
      value={{
        isSidebarMinimized,
        toggleMinimized,
        setShowSettings,
        showSettings,
        showReports,
        setShowReports,
        showRubrics,
        setShowRubrics,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};
