import { useQuery } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRouterReportFilters } from "../../components/molecules/ReportFilters/ReportFilters.context";
import { API_ENDPOINT } from "../../constants";
import { ReportQueryParams, ReportResponse, ReportType } from "../../types/Report";
import { ErrorResponse, requestJson } from "../api";
import { showToast } from "../toast/toast";
import { reportKeys } from "./reportKeys";
import { useGetReportFilters } from "./useGetReportFilters";

export const useGetReport = (params: ReportQueryParams, options?: { enabled: boolean }) => {
  const router = useRouter();
  const { t } = useTranslation(["common", "errors"]);
  const { reportType } = router.query;
  const { areRequiredFiltersMissing } = useRouterReportFilters();
  const { isFetched: areReportFiltersFetched } = useGetReportFilters(params);
  const query = useQuery(
    reportKeys.graph(reportType as ReportType, params),
    () =>
      requestJson<ReportResponse>(`${API_ENDPOINT}/app/reports/${reportType as ReportType}`, {
        queryParams: params,
      })
        .then((data) => data)
        .catch((error: ErrorResponse) => {
          if (error.status === 422) {
            Object.entries(error.cause.errors).forEach(([key, value]) => {
              showToast({
                title: t("errors:validation.required"),
                message: value as unknown as string,
                type: "error",
              });
            });
          }
        }),

    {
      ...options,
      enabled: options?.enabled ?? (Boolean(reportType) && areReportFiltersFetched && !areRequiredFiltersMissing), // only run if reportType and filters are loaded
      placeholderData: {
        data: [],
        averages: [],
        comments: [],
        bandwidth_bg: [],
        bandwidth_fg: [],
        swimlanes: [],
      },
    }
  );

  return query;
};
