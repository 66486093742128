import { FC, useRef } from "react";
import styles from "./ReportGraph.module.scss";

import { Block } from "../../atoms/Block";

import { useTranslation } from "react-i18next";
import { useGetReport } from "../../../services/report/useGetReport";
import { useGetReportFilters } from "../../../services/report/useGetReportFilters";
import { ReportResponse } from "../../../types/Report";
import { ReportBandWidth } from "../../atoms/ReportBandWidth";
import { ReportLine } from "../../atoms/ReportLine";
import { ReportSwimlanes } from "../../atoms/ReportSwimlanes";
import { useRouterReportFilters } from "../../molecules/ReportFilters/ReportFilters.context";
import { ReportGraphLoader } from "./ReportGraphLoader";
import { ReportGraphNoData } from "./ReportGraphNoData";
import { ReportLabel } from "./ReportLabel";
import { ReportTooltip } from "./ReportTooltip";
import { useConstants } from "./useConstants";
import { useDrawAxes } from "./useDrawAxes";
import { usePointer } from "./usePointer";
import { useReportLabels } from "./useReportLabels";
import { useBrush } from "./useBrush";
import { HistoryNavigator } from "../../molecules/HistoryNavigator";

export type ReportGraphProps = {
  dataSet?: ReportResponse;
};

// dataaset
export const ReportGraph: FC<ReportGraphProps> = ({ dataSet: _dataSet }) => {
  const { t } = useTranslation();
  const { filters } = useRouterReportFilters();

  const { data, isFetching: isGraphLoading } = useGetReport({
    ...filters,
  });
  const { isFetching: areFiltersLoading } = useGetReportFilters({
    ...filters,
  });
  const dataSet = _dataSet ?? data!;
  const rootRef = useRef<SVGSVGElement>(null);
  const {
    GRAPH_WIDTH: width,
    GRAPH_HEIGHT: height,
    GRAPH_PADDING_X_START,
    GRAPH_PADDING_Y,
    AVG_GRAY,
    FALLBACK_COLOR,
  } = useConstants();
  const { labels, handleLabelClick } = useReportLabels({ dataSet });
  const { xScale, yScale, xAxisRef, yAxisRef } = useDrawAxes({ dataSet });
  const { handlePointerLeave, handlePointerMove } = usePointer({
    rootRef,
    xScale,
    yScale,
    lines: dataSet?.data ?? [],
  });

  useBrush({
    rootRef,
    xScale,
    yScale,
    lines: dataSet?.data ?? [],
  });

  const isLoading = isGraphLoading || areFiltersLoading;

  return (
    <Block style={{ position: "relative" }}>
      <HistoryNavigator />
      {isLoading && <ReportGraphLoader />}
      {!data || (data?.data.length === 0 && !isLoading && <ReportGraphNoData />)}

      {/* Labels */}
      <div className={styles.labelContainer}>
        {labels.map((label) => (
          <ReportLabel key={label.title} {...label} onClick={handleLabelClick(label)} />
        ))}
      </div>

      {/* SVG */}
      <div className={styles.graphContainer} onPointerLeave={handlePointerLeave}>
        <svg
          viewBox={`0 0 ${width + 2} ${height}`}
          preserveAspectRatio="xMidYMid meet"
          ref={rootRef}
          className={styles.reportGraph}
          onPointerMove={handlePointerMove}
        >
          {/* Bandwidths */}
          <ReportBandWidth
            bandwidth={dataSet?.bandwidth_bg}
            yScaleFunction={yScale}
            xScaleFunction={xScale}
            width={width}
            height={height}
            id="bg"
            fill="rgba(89, 145, 255, 0.05)"
          />
          <ReportBandWidth
            bandwidth={dataSet?.bandwidth_fg}
            yScaleFunction={yScale}
            xScaleFunction={xScale}
            width={width}
            height={height}
            id="fg"
            fill="rgba(89, 145, 255, 0.15)"
          />

          {/* Swimlanes */}
          <ReportSwimlanes swimlanes={dataSet?.swimlanes || []} yScaleFunction={yScale} width={width}></ReportSwimlanes>

          {/* Average line(s) */}
          {dataSet?.averages &&
            dataSet?.averages.length > 0 &&
            dataSet.averages.map((line, index) => (
              <ReportLine
                key={line.title}
                entity={line}
                yScaleFunction={yScale}
                xScaleFunction={xScale}
                id={line.id ?? index.toString()}
                stroke={AVG_GRAY}
                strokeDasharray={"10,10"}
              />
            ))}

          {/* Lines */}
          {dataSet?.data.length > 0 &&
            dataSet.data.map((line, index) => (
              <ReportLine
                key={line.id}
                entity={line}
                yScaleFunction={yScale}
                xScaleFunction={xScale}
                id={line.id ?? index.toString()}
                stroke={`var(--bg-${line.education_level?.label?.toLowerCase()}-base, ${FALLBACK_COLOR})`}
              />
            ))}

          {/* Axes container */}
          <g style={{ color: "rgb(97,110,128)" }}>
            <g ref={xAxisRef} id="x-axis" transform={`translate(0, ${height - GRAPH_PADDING_Y})`}></g>
            <g ref={yAxisRef} id="y-axis" transform={`translate(${GRAPH_PADDING_X_START}, 0)`}></g>
          </g>

          {/* Label on Y axis */}
          <text x={-height / 2} y={10} transform="rotate(-90)" fill="rgb(97,110,128)" textAnchor="middle" fontSize={10}>
            {t("completed_skills_percentage")}
          </text>

          {/* Label on X axis */}
          <text x={width / 2} y={height} fill="rgb(97,110,128)" textAnchor="middle" fontSize={10}>
            {t("date")}
          </text>
        </svg>

        {/* Tooltip (uses positioning data etc. from context)*/}
        <ReportTooltip />
      </div>
    </Block>
  );
};
