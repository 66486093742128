import * as d3 from "d3";
import { FC } from "react";
import { ReportDataPoint, ReportEntity } from "../../../types/Report";
import styles from "../../organisms/ReportGraph/ReportGraph.module.scss";
import { useConstants } from "../../organisms/ReportGraph/useConstants";

type Props = {
  entity?: ReportEntity;
  yScaleFunction?: d3.ScaleLinear<number, number>;
  xScaleFunction?: d3.ScaleTime<number, number>;
  id: string;
  stroke: string;
  strokeDasharray?: string;
};

const LINE_WIDTH = 2;

export const ReportLine: FC<Props> = ({ entity, id, yScaleFunction, xScaleFunction, stroke, strokeDasharray }) => {
  const { DATAPOINT_SIZE } = useConstants();

  if (!yScaleFunction || !xScaleFunction || !entity) {
    return null;
  }

  const lineFunction = d3
    .line<ReportDataPoint>()
    .x((d) => xScaleFunction(new Date(d.date)))
    .y((d) => yScaleFunction(d.progress_percentage))
    .curve(d3.curveMonotoneX); // apply smoothing to the line

  const parsedData = {
    entity: entity,
    d: lineFunction(entity.datapoints) || "",
    stroke: entity.education_level?.label ? `var(--bg-${entity.education_level.label.toLowerCase()}-base)` : "black",
  };

  const lastDataPoint = entity.datapoints[entity.datapoints.length - 1];

  return (
    <g className={styles.reportLine}>
      {/* Draw line */}
      <path
        key={id}
        className={styles.line}
        d={parsedData.d}
        fill="none"
        stroke={stroke}
        strokeDasharray={strokeDasharray ?? ""}
        strokeWidth={LINE_WIDTH}
      />

      {/* Add circles at each data point */}
      {entity.datapoints.map((point, index) => (
        <circle
          key={index}
          cx={xScaleFunction(new Date(point.date))}
          cy={yScaleFunction(point.progress_percentage)}
          r={DATAPOINT_SIZE / 2}
          fill={stroke}
          strokeWidth={0}
        />
      ))}

      {/* Add label at the end of the line */}
      <text
        x={xScaleFunction(new Date(lastDataPoint.date)) + 6}
        y={yScaleFunction(lastDataPoint.progress_percentage) + 2.5}
        textAnchor="left"
        fontSize={8}
        fontWeight={"bold"}
      >
        {entity.title}
      </text>
    </g>
  );
};
