import Link from "next/link";
import { FC, useContext } from "react";
import { useAuthorization } from "../../../context/AuthorizationContext";
import { SidebarContext } from "../../../context/SidebarContext";
import { Button, ButtonVariant } from "../../atoms/Button";
import { ChevronLeft, Question } from "../../atoms/icons";
import styles from "./Sidebar.module.scss";
import { SidebarTypes } from "./Sidebar.types";
import { SidebarMain } from "./SidebarMain";
import { SidebarStudent } from "./SidebarStudent";
import { ApplicationMode, ModeType } from "../../molecules/ApplicationMode/ApplicationMode";
import classNames from "classnames";
import { UserMenu } from "../../molecules/UserMenu/UserMenu";
import { AuthenticatedUser } from "../../molecules/AuthenticatedUser";
import { useMeta } from "../../../context/MetaContext";
import { useKeyboardShortcutsContext } from "../../atoms/KeyboardShortcutsDialog/KeyboardShortcutsDialog";
import { useMode } from "../../molecules/UserMenu/useMode";

export const Sidebar: FC<SidebarTypes> = ({}) => {
  const { isSidebarMinimized, toggleMinimized } = useContext(SidebarContext);
  const { hasRole } = useAuthorization();
  const isNotStudent = !hasRole(["student"]);
  const {
    authorisation: { roles },
  } = useMeta();
  const { showDialog } = useKeyboardShortcutsContext();
  const isStudent = Boolean(roles.find((role) => role.key === "student"));
  const { mode } = useMode();

  return (
    <div data-testid="sidebar" className={styles.sidebarwrapper} data-minimized={isSidebarMinimized}>
      {/* Sidebar toggle size */}
      <div className={styles.toggleButtonContainer}>
        <Button variant={ButtonVariant.PLAIN} onClick={toggleMinimized}>
          <ChevronLeft className={styles.toggleButtonIcon} />
        </Button>
      </div>
      {/* Sidebar content */}
      <div className={styles.sidebar} data-minimized={isSidebarMinimized}>
        <Link href="/">
          <a className={styles.logoContainer}>
            <figure className={styles.logo}>
              <img src="/marcanti-logo.png" width={168} height={52} alt="Marcanti College Logo" />
            </figure>
            <figure className={styles.logoSmall}>
              <img src="/marcanti-logo-small.png" width={27} height={27} alt="Marcanti College Logo" />
            </figure>
          </a>
        </Link>
        <div className={styles.menu}>
          {isNotStudent && <SidebarMain />}
          {!isNotStudent && <SidebarStudent />}
        </div>
        <div className={styles.footer}>
          {!isStudent && (
            <div className={styles.footerActions}>
              <ApplicationMode mode={mode as ModeType} dataMinimized={isSidebarMinimized} />
              <button
                type="button"
                className={classNames(styles.TopbarButton, styles.TopbarIconButton)}
                onClick={showDialog}
                data-minimized={isSidebarMinimized}
              >
                <Question width="100%" height="100%" />
              </button>
            </div>
          )}
          <UserMenu>
            <AuthenticatedUser dataMinimized={isSidebarMinimized} />
          </UserMenu>
        </div>
      </div>
    </div>
  );
};
