import { useEffect, useState } from "react";
import { useRouter } from "next/router";

type HistoryTracker = string[];

const useHistoryTracker = (): [
  HistoryTracker,
  number,
  React.Dispatch<React.SetStateAction<number>>,
  React.Dispatch<React.SetStateAction<boolean>>,
] => {
  const [history, setHistory] = useState<HistoryTracker>([]);
  const [historyIndex, setHistoryIndex] = useState<number>(-1);
  const [skipTracking, setSkipTracking] = useState<boolean>(false);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (!skipTracking) {
        // If navigating forward, add the new URL to the history if it's different from the last one
        if (historyIndex === history.length - 1) {
          if (history[historyIndex] !== url) {
            setHistory((prevHistory) => [...prevHistory, url]);
            setHistoryIndex((prevIndex) => prevIndex + 1);
          }
        } else {
          // If navigating backward, replace the forward history with the new URL if it's different from the last one
          if (history[historyIndex] !== url) {
            setHistory((prevHistory) => [...prevHistory.slice(0, historyIndex + 1), url]);
            setHistoryIndex((prevIndex) => prevIndex + 1);
          }
        }
      } else {
        setSkipTracking(false); // Reset skipTracking after navigation
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events, skipTracking, history, historyIndex]);

  return [history, historyIndex, setHistoryIndex, setSkipTracking];
};

export default useHistoryTracker;
