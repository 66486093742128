import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_ENDPOINT } from "../../constants";
import { ApiBaseResponse } from "../../types/ApiBaseResponse";
import { ApiErrorResponse } from "../../types/ApiErrorResponse";
import { ErrorResponse, requestJson } from "../api";

type HookOptions = {
  onError?: (err: ErrorResponse) => Promise<void>;
};

export type CommentInput = {
  body: string;
  student_id: number;
};

const commentKeys = {
  all: ["comments"] as const,
  post: ["comments", "post"] as const,
};

export const usePostComment = (options: HookOptions = {}) => {
  const queryClient = useQueryClient();
  return useMutation<ApiBaseResponse<CommentInput>, ApiErrorResponse, CommentInput>({
    // @ts-ignore - mutationKey is not a valid prop
    mutationKey: commentKeys.post,
    mutationFn: (payload: CommentInput) => {
      return requestJson<ApiBaseResponse>(`${API_ENDPOINT}/app/comments`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    },
    onError: options.onError,
    onSuccess: () => {
      queryClient.invalidateQueries(commentKeys.all);
    },
  });
};
