import { useRouter } from "next/router";
import useHistoryTracker from "../../../hooks/useHistoryTracker";
import { FC } from "react";
import styles from "./HistoryNavigator.module.scss";
import { PaginationButton } from "../Pagination/PaginationButton";
import { ButtonVariant } from "../../atoms/Button";
import { ChevronLeft, ChevronRight } from "../../atoms/icons";
import { ButtonSize } from "../../atoms/Button/Button.types";

export const HistoryNavigator: FC = () => {
  const [history, historyIndex, setHistoryIndex, setSkipTracking] = useHistoryTracker();
  const router = useRouter();

  const navigateWithoutTracking = async (index: number) => {
    setSkipTracking(true);
    await router.replace(history[index], undefined, { shallow: true });
    setHistoryIndex(index);
  };

  const handlePrev = () => {
    if (historyIndex > 0) {
      navigateWithoutTracking(historyIndex - 1);
    }
  };

  const handleNext = () => {
    if (historyIndex < history.length - 1) {
      navigateWithoutTracking(historyIndex + 1);
    }
  };

  return (
    <div className={styles.container}>
      <PaginationButton
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.XXSMALL}
        onClick={handlePrev}
        disabled={historyIndex <= 0}
        icon={<ChevronLeft />}
      />
      <PaginationButton
        variant={ButtonVariant.PRIMARY}
        size={ButtonSize.XXSMALL}
        onClick={handleNext}
        disabled={historyIndex >= history.length - 1}
        icon={<ChevronRight />}
      />
    </div>
  );
};
