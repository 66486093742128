import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import { ReportGraphProps } from "./ReportGraph";
import { useConstants } from "./useConstants";

type Options = {
  dataSet: NonNullable<ReportGraphProps["dataSet"]>;
};

export function useDrawAxes({ dataSet }: Options = { dataSet: { data: [] } }) {
  const {
    GRAPH_PADDING_X_START,
    GRAPH_PADDING_X_END,
    GRAPH_PADDING_Y,
    GRAPH_WIDTH,
    GRAPH_HEIGHT,
    MAX_VALUE,
    LOCALE_D3,
  } = useConstants();
  const [xScale, setXScale] = useState<d3.ScaleTime<number, number>>();
  const [min, setMin] = useState<Date>();
  const [max, setMax] = useState<Date>();
  const [yScale, setYScale] = useState<d3.ScaleLinear<number, number>>();
  const xAxisRef = useRef<SVGGElement>(null);
  const yAxisRef = useRef<SVGGElement>(null);

  // Draw Axes and setup line data
  useEffect(() => {
    const fallBackMaxDate = new Date(new Date().setDate(new Date().getDate() + 7));

    // set d3 timeformat for x axis labels (months)
    d3.timeFormatDefaultLocale(LOCALE_D3);

    const min = d3.min(dataSet?.data || [], (d) => new Date(d.datapoints[0].date)) || new Date();
    const max =
      d3.max(dataSet?.data || [], (d) => new Date(d.datapoints[d.datapoints.length - 1].date)) || fallBackMaxDate;

    // X scale function definition
    const x = d3
      .scaleUtc()
      .domain([min, max]) // could also default the max to be at least min + 6 months for example
      .range([0 + GRAPH_PADDING_X_START, GRAPH_WIDTH - GRAPH_PADDING_X_END]);

    setXScale(() => x);

    // Y scale function definition
    const y = d3
      .scaleLinear()
      .domain([0, MAX_VALUE])
      // 10 padding top/bottom for text overflow
      .range([GRAPH_HEIGHT - GRAPH_PADDING_Y, 0 + GRAPH_PADDING_Y]);

    setYScale(() => y);

    // Axes. xAxis is a time axis with MMM 'YY format
    const xAxis = d3.axisBottom<Date>(x).ticks(d3.utcMonth.every(1)).tickFormat(d3.timeFormat("%b '%y"));
    const yAxis = d3.axisLeft(y).tickFormat((d) => `${d}%`);

    if (xAxisRef.current) {
      d3.select(xAxisRef.current).selectAll("g.tick").remove();
      d3.select(xAxisRef.current).call(xAxis);

      // grid lines (vertical)
      d3.select(xAxisRef.current)
        .selectAll("g.tick")
        .append("line")
        .attr("stroke", "rgba(75, 85, 99, 0.05)")
        .attr("stroke-width", "1px")
        .attr("y1", -GRAPH_HEIGHT + GRAPH_PADDING_Y * 2)
        .attr("y2", 0);
    }

    if (yAxisRef.current) {
      d3.select(yAxisRef.current).selectAll("g.tick").remove();
      d3.select(yAxisRef.current).call(yAxis);

      // grid lines (horizontal)
      d3.select(yAxisRef.current)
        .selectAll("g.tick")
        .append("line")
        .attr("stroke", "rgba(75, 85, 99, 0.05)")
        .attr("stroke-width", "1px")
        .attr("x1", 0)
        .attr("x2", GRAPH_WIDTH - (GRAPH_PADDING_X_START + GRAPH_PADDING_X_END));
    }
  }, [dataSet, setXScale, setYScale]);

  return {
    xScale: xScale!,
    yScale: yScale!,
    xAxisRef,
    yAxisRef,
  };
}
