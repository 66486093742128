import * as d3 from "d3";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ReportEntity } from "../../../types/Report";
import { useConstants } from "./useConstants";
import { useRouterReportFilters } from "../../molecules/ReportFilters/ReportFilters.context";

type Options = {
  lines: ReportEntity[];
  xScale: d3.ScaleTime<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  rootRef: React.RefObject<SVGSVGElement>;
};

const format = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export function useBrush({ lines, xScale, yScale, rootRef }: Options) {
  const { GRAPH_WIDTH, GRAPH_HEIGHT, GRAPH_PADDING_Y, GRAPH_PADDING_X_START, GRAPH_PADDING_X_END } = useConstants();

  const { addFilter } = useRouterReportFilters();

  // Create a unique identifier for xScale based on its domain and range
  const xScaleKey = JSON.stringify({ domain: xScale?.domain(), range: xScale?.range() });

  const setRange = useCallback(
    (event: any) => {
      if (!xScale || !event.selection) {
        console.log("Scale or selection not defined", {
          xScale,
          selection: event.selection,
        });
        return;
      }

      const selection = event.selection;
      const [x0, x1] = selection.map(xScale.invert);
      addFilter("date_at", [format(x0), format(x1)]);
    },
    [xScale, addFilter]
  );

  const brush = useMemo(() => {
    return d3
      .brushX()
      .extent([
        [GRAPH_PADDING_X_START, GRAPH_PADDING_Y],
        [GRAPH_WIDTH - GRAPH_PADDING_X_END, GRAPH_HEIGHT - GRAPH_PADDING_Y],
      ])
      .on("end", setRange);
  }, [GRAPH_PADDING_X_START, GRAPH_PADDING_X_END, GRAPH_PADDING_Y, GRAPH_WIDTH, GRAPH_HEIGHT, setRange]);

  // Set up the brush when xScaleKey changes
  useEffect(() => {
    if (!xScaleKey) return;

    // Remove any existing brush to avoid duplicates
    d3.select(rootRef.current).select(".brush").remove();

    // Add the brush to the SVG
    d3.select(rootRef.current).append("g").attr("class", "brush").call(brush);
  }, [xScaleKey, brush, rootRef]);

  const resetBrush = useCallback(() => {
    // @ts-ignore
    d3.select(rootRef.current).select(".brush").call(brush.move, null); // Clear the selection
  }, [rootRef, brush]);

  return { resetBrush };
}
