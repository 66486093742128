import * as d3 from "d3";
import { FC } from "react";
import { ReportBandwidth } from "../../../types/Report";
import { useConstants } from "../../organisms/ReportGraph/useConstants";

type Props = {
  bandwidth?: ReportBandwidth[];
  yScaleFunction?: d3.ScaleLinear<number, number>;
  xScaleFunction?: d3.ScaleTime<number, number>;
  width: number;
  height: number;
  id: string;
  fill: string;
};

export const ReportBandWidth: FC<Props> = ({ bandwidth, yScaleFunction, xScaleFunction, width, height, id, fill }) => {
  const { GRAPH_WIDTH, GRAPH_PADDING_X_END, GRAPH_PADDING_X_START, GRAPH_PADDING_Y } = useConstants();
  if (!yScaleFunction || !xScaleFunction || !bandwidth || bandwidth.length === 0) {
    return null;
  }

  const bandWidthFunction = d3
    .area<ReportBandwidth>()
    .x((d) => xScaleFunction(new Date(d.date)))
    .y0((d) => yScaleFunction(d.progress_percentage - d.margin / 2))
    .y1((d) => yScaleFunction(d.progress_percentage + d.margin / 2));

  const parsedData = bandWidthFunction(bandwidth ?? []) || "";

  return (
    <>
      <path key={id} d={parsedData} stroke="none" fill={fill} />
      {/* Bandwidth graph is an area which can go out of bound due to margin (> 100% or < 0%) */}
      {/* Overlay 2 white rectangles to hide these out of bound parts */}
      <g>
        <rect
          x={GRAPH_PADDING_X_START}
          y="0"
          width={width - (GRAPH_PADDING_X_START + GRAPH_PADDING_X_END)}
          height={GRAPH_PADDING_Y}
          fill="white"
        />
        <rect
          x={GRAPH_PADDING_X_START}
          y={height - GRAPH_PADDING_Y}
          width={width - (GRAPH_PADDING_X_START + GRAPH_PADDING_X_END)}
          height={GRAPH_PADDING_Y}
          fill="white"
        />
        {/* And also on the left part, since the BE returns periods which are a longer range than the data points */}
        <rect x="0" y="0" width={GRAPH_PADDING_X_START} height={height} fill="white" />
        <rect
          x={GRAPH_PADDING_X_START + (GRAPH_WIDTH - (GRAPH_PADDING_X_START + GRAPH_PADDING_X_END))}
          y="0"
          width={GRAPH_PADDING_X_END + 10}
          height={height}
          fill="white"
        />
      </g>
    </>
  );
};
