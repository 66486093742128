import { useTranslation } from "react-i18next";

export function useConstants() {
  const { t } = useTranslation();
  const MAX_VALUE = 100;
  const AVG_GRAY = "rgb(178, 178, 178)";
  const FALLBACK_COLOR = "var(--color-primary-600)";
  const DATAPOINT_SIZE = 4;

  // Declare the chart base dimensions and margins.
  // chart/svg will grow to fill container but will determine 'zoom' feeling / size of fonts etc.
  const GRAPH_WIDTH = 800;
  const GRAPH_HEIGHT = GRAPH_WIDTH / 2.5;
  const GRAPH_PADDING_X_START = 50; // v-spacing for y axis labels
  const GRAPH_PADDING_X_END = 30; // v-spacing for y axis labels
  const GRAPH_PADDING_Y = 30; // v-spacing for x axis labels and y axis label

  // create locale based on user language
  const LOCALE_D3: d3.TimeLocaleDefinition = {
    dateTime: "%A %e %B %Y %T",
    date: "%d-%m-%Y",
    time: "%H:%M:%S",
    periods: ["AM", "PM"],
    days: [
      t("days.monday"),
      t("days.tuesday"),
      t("days.wednesday"),
      t("days.thursday"),
      t("days.friday"),
      t("days.saturday"),
      t("days.sunday"),
    ],
    shortDays: [
      t("days_abbreviations.mo"),
      t("days_abbreviations.tu"),
      t("days_abbreviations.we"),
      t("days_abbreviations.th"),
      t("days_abbreviations.fr"),
      t("days_abbreviations.sa"),
      t("days_abbreviations.su"),
    ],
    months: [
      t("months.january"),
      t("months.february"),
      t("months.march"),
      t("months.april"),
      t("months.may"),
      t("months.june"),
      t("months.july"),
      t("months.august"),
      t("months.september"),
      t("months.october"),
      t("months.november"),
      t("months.december"),
    ],
    shortMonths: [
      t("months_abbreviations.jan"),
      t("months_abbreviations.feb"),
      t("months_abbreviations.mar"),
      t("months_abbreviations.apr"),
      t("months_abbreviations.may"),
      t("months_abbreviations.jun"),
      t("months_abbreviations.jul"),
      t("months_abbreviations.aug"),
      t("months_abbreviations.sep"),
      t("months_abbreviations.oct"),
      t("months_abbreviations.nov"),
      t("months_abbreviations.dec"),
    ],
  };

  return {
    MAX_VALUE,
    AVG_GRAY,
    FALLBACK_COLOR,
    DATAPOINT_SIZE,
    GRAPH_WIDTH,
    GRAPH_HEIGHT,
    GRAPH_PADDING_X_START,
    GRAPH_PADDING_X_END,
    GRAPH_PADDING_Y,
    LOCALE_D3,
  };
}
