import { useForm } from "react-hook-form";
import { useRouterReportFilters } from "../../molecules/ReportFilters/ReportFilters.context";
import { useGetReport } from "../../../services/report/useGetReport";
import styles from "./Comment.module.scss";
import { Textarea } from "../../atoms/Textarea";
import { Button } from "../../atoms/Button";
import { Avatar } from "../../atoms/Avatar";
import { getInitialsFromName } from "../../../helpers/format/user";
import { CommentInput, usePostComment } from "../../../services/comments/usePostComments";

export const Comment = () => {
  const { filters } = useRouterReportFilters();
  const studentData = filters["filter[student_id][]"] ?? filters["filter[student_id]"];

  let studentId: string = "";

  if (Array.isArray(studentData)) {
    studentId = studentData[0];
  } else if (typeof studentData === "string") {
    studentId = studentData;
  }

  const {
    data,
    isFetching: isGraphLoading,
    refetch,
  } = useGetReport({
    ...filters,
  });
  const { mutate: postComment } = usePostComment();
  const { register, handleSubmit, reset } = useForm<CommentInput>({
    defaultValues: {
      student_id: parseInt(studentId),
    },
  });

  const comments = data?.comments;

  const onSubmit = (data: CommentInput) => {
    postComment(
      { ...data, student_id: parseInt(studentId) },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );

    reset();
  };

  if (!studentId || (typeof studentData === "object" && studentData.length > 1)) {
    return null;
  }

  return (
    <div className={styles.comments}>
      <h2>Opmerkingen</h2>
      <div className={styles.commentsInner}>
        <form className={styles.commentInput} onSubmit={handleSubmit(onSubmit)}>
          <label htmlFor="body">Opmerking</label>
          <span>&nbsp; *</span>
          <Textarea placeholder="Typ hier je opmerking" id="comment" {...register("body", { required: true })} />
          <Button type="submit">Opmerking plaatsen</Button>
        </form>
        {comments &&
          comments.map((comment, index) => (
            <div key={index} className={styles.commentSection}>
              <Avatar initials={getInitialsFromName(comment.user?.display_name || "- -")} />
              <div className={styles.commentSectionComment}>
                <div className={styles.commentSectionCommentDetails}>
                  {comment.user?.display_name || "Onbekend"} <span>{comment.created_at}</span>
                </div>
                <div className={styles.commentSectionCommentBody}>{comment.body}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
