import { useTranslation } from "react-i18next";
import { useAuthorization } from "../../../context/AuthorizationContext";
import { Menu } from "../../molecules/Menu";

import classNames from "classnames";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { SidebarContext } from "../../../context/SidebarContext";
import { UserLockContext } from "../../../context/UserLockContext";
import { validReportTypes } from "../../../pages/report/[reportType]";
import { useGetClasses } from "../../../services/classes/useGetClasses";
import { AuthorizedCan } from "../../atoms/AuthorizedCan/AuthorizedCan";
import {
  Calendar,
  ChevronDown,
  Cohort,
  Home,
  Roles,
  Settings,
  Group,
  Tune,
  User,
  Users,
  Edit,
  Search,
} from "../../atoms/icons";
import { ChartIcon } from "../../atoms/icons/Chart";
import { MenuLink } from "../../molecules/Menu/MenuLink";
import styles from "./Sidebar.module.scss";
import { ChartCollectionIcon } from "../../atoms/icons/ChartCollection";

export const SidebarMain: React.FC = () => {
  const { t } = useTranslation();
  const { isLocked } = useContext(UserLockContext);
  const {
    setShowSettings,
    showSettings,
    isSidebarMinimized,
    showReports,
    setShowReports,
    setShowRubrics,
    showRubrics,
  } = useContext(SidebarContext);
  const { hasPermission } = useAuthorization();
  const { data: classes, isLoading } = useGetClasses();
  const router = useRouter();

  const displaySubjectGroups = hasPermission("class_show_students");
  const displayStudents = hasPermission("class_show_students");
  const displayAssessmentRounds = hasPermission("assessment_round_index");
  const subjectGroupsLink =
    classes && classes.length > 0 ? `/classes/${classes[0].id}/list` : isLoading ? "#" : "/classes/1/list";

  const hasSettingsPermissions =
    hasPermission(["rubric_index", "period_index"]) ||
    hasPermission("cohort_index") ||
    hasPermission("permission_index") ||
    hasPermission("user_index");

  const hasReportPermissions =
    hasPermission("report_student_show") ||
    hasPermission("report_class_student_show") ||
    hasPermission("report_class_show") ||
    hasPermission("report_teacher_class_show");

  return (
    <Menu>
      <MenuLink href="/">
        <Home />
        <span className={styles.menuItemText}>{t("dashboard")}</span>
      </MenuLink>

      {displayAssessmentRounds && (
        <MenuLink href="/assessment-rounds">
          <Calendar />
          <span className={styles.menuItemText}>{t("assessment_rounds")}</span>
        </MenuLink>
      )}

      {displaySubjectGroups && (
        <MenuLink href={subjectGroupsLink} active={router.pathname.includes("/classes/")}>
          <Group />
          <span className={styles.menuItemText}>{t("subject_groups")}</span>
        </MenuLink>
      )}

      {displayStudents && (
        <MenuLink href="/students">
          <User />
          <span className={styles.menuItemText}>{t("students")}</span>
        </MenuLink>
      )}

      {hasReportPermissions && (
        <MenuLink onClick={() => setShowReports(!showReports)}>
          <ChartCollectionIcon />
          <span className={styles.menuItemText}>{t("reports")}</span>
          <ChevronDown className={classNames([styles.verticalChevron, showReports ? styles.verticalFlip : ""])} />
        </MenuLink>
      )}

      {/* reports submenu */}
      {showReports && (
        <div className={classNames([styles.subMenu, isSidebarMinimized ? null : styles.subMenuIndent])}>
          {validReportTypes.map(
            (reportType) =>
              hasPermission(reportType.permission) && (
                <MenuLink
                  key={reportType.key}
                  href={`/report/${reportType.route}`}
                  active={router.query?.reportType === reportType.route}
                >
                  <ChartIcon />
                  <span className={styles.menuItemText}>{t(reportType.translationKey)}</span>
                </MenuLink>
              )
          )}
        </div>
      )}

      {!isLocked && hasSettingsPermissions && (
        <MenuLink onClick={() => setShowSettings(!showSettings)}>
          <Settings />
          <span className={styles.menuItemText}>{t("settings")}</span>
          <ChevronDown className={classNames([styles.verticalChevron, showSettings ? styles.verticalFlip : ""])} />
        </MenuLink>
      )}

      {/* settings submenu */}
      {showSettings && !isLocked && (
        <div className={classNames([styles.subMenu, isSidebarMinimized ? null : styles.subMenuIndent])}>
          {/* Rubrics main item */}
          {!isLocked && hasSettingsPermissions && (
            <MenuLink onClick={() => setShowRubrics(!showRubrics)}>
              <Tune />
              <span className={styles.menuItemText}>{t("rubrics")}</span>
              <ChevronDown className={classNames([styles.verticalChevron, showRubrics ? styles.verticalFlip : ""])} />
            </MenuLink>
          )}

          {/* Rubrics submenu */}
          {showRubrics && !isLocked && (
            <div className={classNames([styles.subMenu, isSidebarMinimized ? null : styles.subMenuIndent])}>
              <AuthorizedCan has={["rubric_index", "period_index"]}>
                <MenuLink href="/settings/rubrics">
                  <Search />
                  <span className={styles.menuItemText}>{t("rubrics_overview")}</span>
                </MenuLink>
              </AuthorizedCan>
              <AuthorizedCan has={["rubric_index", "period_index"]}>
                <MenuLink href="/settings/rubrics/edit">
                  <Edit />
                  <span className={styles.menuItemText}>{t("rubrics_edit")}</span>
                </MenuLink>
              </AuthorizedCan>
            </div>
          )}

          <AuthorizedCan has={["cohort_index"]}>
            <MenuLink
              href="/settings/cohorts"
              activeRoutes={[
                "/settings/cohorts/create",
                "/settings/cohorts/[cohortId]",
                "/settings/cohorts/[cohortId]/edit",
              ]}
            >
              <Cohort />
              <span className={styles.menuItemText}>{t("cohorts")}</span>
            </MenuLink>
          </AuthorizedCan>
          <AuthorizedCan has={["permission_index"]}>
            <MenuLink href="/settings/permissions">
              <Roles />
              <span className={styles.menuItemText}>{t("roles")}</span>
            </MenuLink>
          </AuthorizedCan>
          <AuthorizedCan has={["user_index"]}>
            <MenuLink href="/settings/users" activeRoutes={["/settings/users/[userId]"]}>
              <Users />
              <span className={styles.menuItemText}>{t("users")}</span>
            </MenuLink>
          </AuthorizedCan>
        </div>
      )}
    </Menu>
  );
};
